import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h4`
    margin: 0 0 20px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

const Button = styled.button`
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }

    & + & {
        margin-left: 10px;
    }
`;

const SelectedCategories = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
`;
const Textarea = styled.textarea`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical; // Optional: allows vertical resizing
`;

const CategoryTag = styled.div`
    background: #007bff;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    align-items: center;

    button {
        background: none;
        border: none;
        color: white;
        margin-left: 5px;
        cursor: pointer;
    }
`;

export default function EditVideoModal({ video, onClose, onUpdate }) {
    const [title, setTitle] = useState(video.title);
    const [description, setDescription] = useState(video.description);
    const [tags, setTags] = useState(video.tags);
    const [categories, setCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(video.categories.split(',').map(cat => cat.trim()));
    const [errors, setErrors] = useState({}); // State for error messages

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const res = await axios.get(`${baseUrl}/category/get-categories`, { headers: { Authorization: token } });
                setAllCategories(res.data.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, [baseUrl, token]);

    const validateForm = () => {
        const newErrors = {};
        if (!title) newErrors.title = 'Title cannot be empty.';
        if (!description) newErrors.description = 'Description cannot be empty.';
        if (!tags) newErrors.tags = 'Tags cannot be empty.';
        if (selectedCategories.length === 0) newErrors.categories = 'At least one category must be selected.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return; // Prevent submission if validation fails

        try {
            await axios.post(`${baseUrl}/videos/update-video`, {
                id: video.id,
                title,
                description,
                tags,
                categories: selectedCategories,
            }, { headers: { Authorization: token } });

            onUpdate({ ...video, title, description, categories: selectedCategories });
            onClose();
            window.location.reload();
        } catch (error) {
            console.error('Error updating video:', error);
        }
    };

    useEffect(() => {
        setTitle(video.title);
        setDescription(video.description);
        setSelectedCategories(video.categories.split(',').map(cat => cat.trim()));
    }, [video]);

    const handleAddCategory = (e) => {
        const category = e.target.value;
        if (category && !selectedCategories.includes(category)) {
            setSelectedCategories([...selectedCategories, category]);
        }
        e.target.value = '';
    };

    const handleRemoveCategory = (categoryToRemove) => {
        setSelectedCategories(selectedCategories.filter(category => category !== categoryToRemove));
    };

    return (
        <ModalContainer>
            <ModalContent>
                <Title>Edit Video</Title>
                <form onSubmit={handleSubmit}>
                    <Input 
                        type="text" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        placeholder="Title" 
                    />
                    {errors.title && <p style={{ color: 'red' }}>{errors.title}</p>} {/* Error message */}

                    <Textarea 
                        type="text" 
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)} 
                        placeholder="Description" 
                        rows={4}
                    />
                    {errors.description && <p style={{ color: 'red' }}>{errors.description}</p>} {/* Error message */}

                    <Input 
                        type="text" 
                        value={tags} 
                        onChange={(e) => setTags(e.target.value)} 
                        placeholder="Tags" 
                    />
                    {errors.tags && <p style={{ color: 'red' }}>{errors.tags}</p>} {/* Error message */}

                    <Select onChange={handleAddCategory}>
                        <option value="">Select a category to add</option>
                        {allCategories.map((category) => (
                            <option key={category.id} value={category.name}>
                                {category.name}
                            </option>
                        ))}
                    </Select>
                    {errors.categories && <p style={{ color: 'red' }}>{errors.categories}</p>} {/* Error message */}

                    <SelectedCategories>
                        {selectedCategories.map((category) => (
                            <CategoryTag key={category}>
                                {category}
                                <button type="button" onClick={() => handleRemoveCategory(category)}>x</button>
                            </CategoryTag>
                        ))}
                    </SelectedCategories>

                    <ButtonContainer>
                        <Button type="submit">Update</Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ButtonContainer>
                </form>
            </ModalContent>
        </ModalContainer>
    );
}
