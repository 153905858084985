import CryptoJS from 'crypto-js';

// AES encryption function
export function encrypt(id) {
    const secretKey = CryptoJS.enc.Utf8.parse("1234567890123456"); // Key must be 16 bytes
    const iv = CryptoJS.enc.Utf8.parse("1234567890123456"); // IV must be 16 bytes

    // Encrypt the input (convert to string)
    const encrypted = CryptoJS.AES.encrypt(id.toString(), secretKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();

    // Encode as Base64 and replace URL-unsafe characters
    const base64Encoded = encrypted
        .replace(/\+/g, '-')  // Replace `+` with `-`
        .replace(/\//g, '_')  // Replace `/` with `_`
        .replace(/=+$/, '');  // Remove any `=` padding at the end

    console.log("Encrypted (URL-safe Base64):", base64Encoded);
    return base64Encoded;
}
