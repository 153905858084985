import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from './Card';
import axios from 'axios';
import EditVideoModal from './EditVideoModal'; // Import the EditVideoModal component

const Container = styled.div`
    position: relative; 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 40px;
    width: 100%; 
`;

const Headings = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    margin-right: 0px;
`;

const ScrollableDiv = styled.div`
    padding: 10px;
    border: 1px solid #ccc; 
    border-radius: 8px; 
    background-color: #f9f9f9; 
    margin-top: 10px; 
    height: 80vh;
    overflow: scroll;
`;

export default function AdminUploads() {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [videos, setVideos] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchVideos = async () => {
            const res = await axios.get(`${baseUrl}/videos/get-videos-by-admin`, { headers: { Authorization: token } });
            setVideos(res.data.data);
        };
        fetchVideos();
    }, []);

    const handleDelete = (id) => {
        setVideos(videos.filter((video) => video.id !== id));
    };

    const handleEditClick = (video) => {
        setCurrentVideo(video);
        setIsModalOpen(true); // Open the modal
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setCurrentVideo(null); // Reset current video
    };

    const handleUpdateVideo = (updatedVideo) => {
        setVideos(videos.map(video => (video.id === updatedVideo.id ? updatedVideo : video)));
    };

    return (
        <Container>
            <Headings>
                <h3>Admin Uploads</h3> 
            </Headings>
            <ScrollableDiv>
                {videos.map((video) => (
                    <Card 
                        key={video.id} 
                        video={video} 
                        onDelete={handleDelete} 
                        onEdit={handleEditClick} // Pass the edit function
                    />
                ))}
            </ScrollableDiv>

            {isModalOpen && currentVideo && ( // Render modal when open
                <EditVideoModal 
                    video={currentVideo} 
                    onClose={handleModalClose} 
                    onUpdate={handleUpdateVideo} 
                />
            )}
        </Container>
    );
}
