import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import axios from 'axios';
import MsgCards from '../MsgCards';
import Loader from '../Loader';

const Container = styled.div`
  flex: 1;
  margin-bottom: 60px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    padding-bottom: 10px;
  }
`;

export default function Approach() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  const [messages, setMessages] = useState([]);
  const [loader , setLoader] = useState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoader(true);
      const res = await axios.get(`${baseUrl}/videos/get-queries`, { headers: { Authorization: token, } });
      setMessages(res.data.data);
      setLoader(false);
    }
    fetchMessages();
  }, []);

  const handleDelete = (id) => {
    setMessages(messages.filter((msg) => msg.id !== id));
}

  return (
    <Container>
      {loader ? <Loader/> : null}
      {messages.map((message) => (
        <MsgCards key={message._id} message={message} onDelete={handleDelete} />
      ))}
    </Container>
  )
}
