import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'; // Import Edit icon
import { encrypt } from '../utils/encryption';


const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    background-color: lightgray;
    width: 100%;
    height: auto;
    margin-bottom: 60px;
    cursor: pointer;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 100%;
`;

const Title = styled.h4`
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
`;

const Description = styled.h5`
    margin-top: 5px;
    font-size: 14px;
    color: #666;  
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
    cursor: pointer; 
`;

const FullText = styled.p`
    margin-top: 5px;
    font-size: 14px;
    color: #666;
`;

const Author = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.textSoft};
    margin-right: 10px;
`;

const Button = styled.button`
    padding: 2px 10px;
    background-color: transparent;
    border: 1px solid black;
    color: black;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: black;
        color: white;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Buttons = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 10px;
`;

export default function Card({ video, onDelete, onEdit }) { // Add onEdit prop
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    const encryptedId = encrypt(video?.id);

    const [isTitleExpanded, setIsTitleExpanded] = useState(false);
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

    const handleDelete = async () => {
        try {
            await axios.delete(`${baseUrl}/videos/delete?id=${video.id}`, { headers: { Authorization: token } });
            onDelete(video._id);
            console.log('Video deleted:', video.id);
            window.location.reload();
        } catch (error) {
            console.error('Error deleting video:', error);
        }
    };

    const truncateText = (text, maxWords) => {
        const words = text.split(' ');
        return words.length > maxWords ? words.slice(0, maxWords).join(' ') + '...' : text;
    };

    return (
        <Container>
            <Details>
                <Wrapper>
                    <Title onClick={() => setIsTitleExpanded(!isTitleExpanded)}>
                        {isTitleExpanded ? video.title : truncateText(video.title, 10)}
                    </Title>
                    <Author>by {video.author}</Author>
                </Wrapper>
                <div>
                    <a href={`https://insights.dawdle.live/insights/${encryptedId}`} target="_blank" rel="noopener noreferrer">
                        Video
                    </a>
                </div>
                {isDescriptionExpanded ? (
                    <FullText onClick={() => setIsDescriptionExpanded(false)}>
                        {video.description}
                    </FullText>
                ) : (
                    <Description onClick={() => setIsDescriptionExpanded(true)}>
                        {truncateText(video.description, 30)}
                    </Description>
                )}
                <Buttons>
                    <Button onClick={handleDelete}>
                        <DeleteIcon />
                        Delete
                    </Button>
                    <Button onClick={() => onEdit(video)}> {/* Call onEdit when clicked */}
                        <EditIcon />
                        Edit
                    </Button>
                </Buttons>
            </Details>
        </Container>
    );
}
