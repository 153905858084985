import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAUMLCeh7eK6_0Q96CaBeMeFsm4wA445_s",
  authDomain: "video-4f294.firebaseapp.com",
  projectId: "video-4f294",
  storageBucket: "video-4f294.appspot.com",
  messagingSenderId: "169202807768",
  appId: "1:169202807768:web:9d85a27daf188e18b5f8ad",
  measurementId: "G-5WJCWEYK9P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export default app;