import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Loader from './Loader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  width: 90%;
  max-width: 600px;
  background-color: ${({ theme }) => theme.bgLighter};
  color: ${({ theme }) => theme.text};
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 90%;
    margin: 10px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const Input = styled.input`
  width: 70%;
  border: 1px solid ${({ theme }) => theme.soft};
  padding: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  font-size: 16px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FileInput = styled.input`
  width: 70%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.soft};
  border-radius: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Button = styled.button`
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${({ theme }) => theme.soft};
  color: ${({ theme }) => theme.text};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.bgDark};
  }
`;

const CategoryList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 10px;
`;

const CategoryItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.soft};
  background-color: ${({ theme }) => theme.bgLighter};
  border-radius: 5px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
  margin-top: -10px;
`;

export default function CategoryUpload() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [toggleList, setToggleList] = useState(false);
  const [categories, setCategories] = useState([]);
  const [input, setInput] = useState({ name: "" });
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageError(""); // Reset previous errors

    if (!file) return;

    // Validate file type
    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      setImageError("Only JPG and PNG formats are allowed.");
      setImage(null);
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) { // 5MB
      setImageError("File size must be less than 5MB.");
      setImage(null);
      return;
    }

    setImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if the input is blank
    if (!input.name.trim()) {
      alert("Category name cannot be empty.");
      return;
    }

    // Validation: Check if the image is uploaded
    if (!image) {
      setImageError("Please upload an image for the category.");
      return;
    }

    // Validation: Check if the category already exists
    const isDuplicate = categories.some(category => category.name.toLowerCase() === input.name.trim().toLowerCase());
    if (isDuplicate) {
      alert("This category already exists.");
      return;
    }

    try {
      setLoader(true);
      const formData = new FormData();
      formData.append('name', input.name);
      formData.append('image', image);

      const res = await axios.post(`${baseUrl}/category/add-category`, formData, {
        headers: { 
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      });
      
      setInput({ name: "" });
      setImage(null);
      setCategories([...categories, res.data.data]);
      if(res.status === 200) {
         window.location.reload();
      }
    } catch (error) {
      console.log(error);
    } 
    finally {
      setLoader(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await axios.get(`${baseUrl}/category/get-categories`, { headers: { Authorization: token } });
      setCategories(res.data.data);
      console.log(res.data.data);
    };
    fetchCategories();
  }, [baseUrl, token]);

  const handleDelete = async (id) => {
    try {
      await axios.post(`${baseUrl}/category/delete-category`, { id }, { headers: { Authorization: token } });
      setCategories(categories.filter((category) => category.id !== id));
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Wrapper>
      {loader && <Loader />}
      <InputWrapper>
        <Input
          type="text"
          placeholder="Enter Category Name"
          name="name"
          value={input.name}
          onChange={handleChange}
        />
        <FileInput
          type="file"
          accept=".jpg,.png"
          onChange={handleImageChange}
        />
        {imageError && <ErrorText>{imageError}</ErrorText>}
        <Button onClick={handleSubmit}>Submit</Button>
        <Button onClick={() => setToggleList(!toggleList)}>
          {toggleList ? "Close" : "Show"}
        </Button>
      </InputWrapper>
      {toggleList && (
        <CategoryList>
          {categories.map((category) => (
            <CategoryItem key={category.id}>
              <span>{category.name}</span>
              <Button onClick={() => handleDelete(category.id)}>Delete</Button>
            </CategoryItem>
          ))}
        </CategoryList>
      )}
    </Wrapper>
  );
}
