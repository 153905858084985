import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

const MAX_TITLE_WORDS = 50;
const MAX_AUTHOR_WORDS = 50;
const MAX_DESCRIPTION_WORDS = 1000;
const MAX_VIDEO_SIZE_MB = 100;
const MAX_IMAGE_SIZE_MB = 5;

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

const isValidLinkedInURL = (url) => {
  const regex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;
  return regex.test(url);
};

const Container = styled.div`
  width: 90%;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-top: 30px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.bgLighter};
  color: ${({ theme }) => theme.text};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
    gap: 15px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.soft};
  padding: 10px;
  border-radius: 3px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const Desc = styled.textarea`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.soft};
  padding: 10px;
  border-radius: 3px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.soft};
  border: none;
  color: ${({ theme }) => theme.text};
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  align-self: flex-end;

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.bgLighter};
  border: 1px solid ${({ theme }) => theme.soft};
  border-radius: 3px;
  text-align: left;
  cursor: pointer;
  color:white;
`;

const DropdownList = styled.ul`
  position: absolute;
  background-color: ${({ theme }) => theme.bgLighter};
  border: 1px solid ${({ theme }) => theme.soft};
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.soft};
  }
`;

export default function Upload() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [video, setVideo] = useState(undefined);
  const [thumbnail, setThumbnail] = useState(undefined); // New state for thumbnail
  const [inputs, setInputs] = useState({});
  const [tags, setTags] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [categories, setCategories] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadFailed, setUploadFailed] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleTags = (e) => {
    setTags(e.target.value.split(','));
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await axios.get(`${baseUrl}/category/get-categories`, {
        headers: { Authorization: token },
      });
      setCategories(res?.data?.data);
    };
    fetchCategories();
  }, [baseUrl, token]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCategorySelect = (category) => {
    if (!selectedCategories.includes(category)) {
      setSelectedCategories([...selectedCategories, category]);
    }
    setIsDropdownOpen(false);
  };

  // Validation function that checks required fields and other conditions
  const validateForm = () => {
    const titleWords = inputs.title?.trim().split(/\s+/).length || 0;
    const authorWords = inputs.author?.trim().split(/\s+/).length || 0;
    const descriptionWords = inputs.description?.trim().split(/\s+/).length || 0;

    if (!video) {
      setErrorMessage('Please upload a video.');
      return false;
    }
    if (!inputs.title) {
      setErrorMessage('Title is required.');
      return false;
    }
    if (titleWords > MAX_TITLE_WORDS) {
      setErrorMessage('Title cannot exceed 50 words.');
      return false;
    }
    if (!inputs.author) {
      setErrorMessage('Author is required.');
      return false;
    }
    if (authorWords > MAX_AUTHOR_WORDS) {
      setErrorMessage('Author name cannot exceed 50 words.');
      return false;
    }
    if (!inputs.description) {
      setErrorMessage('Description is required.');
      return false;
    }
    if (descriptionWords > MAX_DESCRIPTION_WORDS) {
      setErrorMessage('Description cannot exceed 1000 words.');
      return false;
    }
    if (inputs.description.length < 10) {
      setErrorMessage('Description must be at least 10 characters long.');
      return false;
    }  
    if (!tags || tags.length === 0) {
      setErrorMessage('At least one tag is required.');
      return false;
    }
    if (selectedCategories.length === 0) {
      setErrorMessage('At least one category must be selected.');
      return false;
    }
    if (inputs.linkedIn && !isValidLinkedInURL(inputs.linkedIn)) {
      setErrorMessage('LinkedIn URL is not valid.');
      return false;
    }
    if (video.size / 1024 / 1024 > MAX_VIDEO_SIZE_MB) {
      setErrorMessage('Video size cannot exceed 100MB.');
      return false;
    }
    if (!thumbnail) {
      setErrorMessage('Please upload a thumbnail.');
      return false;
    }
    if (!ACCEPTED_IMAGE_TYPES.includes(thumbnail.type)) {
      setErrorMessage('Thumbnail must be a valid image file (jpeg, jpg, png).');
      return false;
    }
    if (thumbnail.size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
      setErrorMessage('Thumbnail size cannot exceed 5MB.');
      return false;
    }
    return true;
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop if validation fails
    const formData = new FormData();
    setLoader(true);
    setUploadFailed(false); // Reset upload failure state

    formData.append('video', video);
    formData.append('thumbnail', thumbnail);
    formData.append('title', inputs.title);
    formData.append('description', inputs.description);
    formData.append('tags', tags);
    formData.append('categories', selectedCategories.join(', '));
    formData.append('author', inputs.author);
    formData.append('linkedIn', inputs.linkedIn);

    try {
      const res = await axios.post(`${baseUrl}/videos/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      });

      if (res?.status === 200) {
        setLoader(false);
        setSuccessMessage('Video uploaded successfully');
        setInputs({});
        setTags('');
        setVideo(null);
        setSelectedCategories([]);
        
        // Reset the form and reload the page after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        
        navigate('/');
      }
    } catch (error) {
      setLoader(false);
      setUploadFailed(true); // Mark upload as failed
      setErrorMessage('Something went wrong, please try again later.');
    }
    finally {
      setLoader(false);
    }
  };

  return (
    <Container>
      {loader && <Loader />}
      <Wrapper>
        <Title>Upload a New Video</Title>
        <Input
          type="file"
          accept="video/*"
          onChange={(e) => setVideo(e.target.files[0])}
        />
        <Input
          type="text"
          placeholder="Title"
          name="title"
          onChange={handleChange}
        />
        <Input
          type="text"
          placeholder="Author"
          name="author"
          onChange={handleChange}
        />
        <Desc
          placeholder="Description"
          rows={4}
          name="description"
          onChange={handleChange}
        />
        <Input
          type="text"
          placeholder="Tags (comma separated)"
          onChange={handleTags}
        />
        <Input
          type="url"
          placeholder="LinkedIn url"
          name="linkedIn"
          onChange={handleChange}
        />

        {/* Thumbnail Input */}
        <Input
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          onChange={(e) => setThumbnail(e.target.files[0])}
        />

        <DropdownContainer>
          <DropdownButton onClick={toggleDropdown}>
            {selectedCategories.length > 0
              ? selectedCategories.join(', ')
              : 'Select Categories'}
          </DropdownButton>
          {isDropdownOpen && (
            <DropdownList>
              {categories.map((category) => (
                <DropdownItem
                  key={category.id}
                  onClick={() => handleCategorySelect(category.name)}
                >
                  {category.name}
                </DropdownItem>
              ))}
            </DropdownList>
          )}
        </DropdownContainer>

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {uploadFailed && (
          <p style={{ color: 'red' }}>
            Upload failed. Please try again later.
          </p>
        )}

        <Button onClick={handleUpload}>Upload</Button>
      </Wrapper>
    </Container>
  );
}
